<template>
    <section class="section media-section">
    <div class="container">
         <div class="columns is-multiline is-vcentered">
            <div class="column is-12">
              <p class="title has-text-centered" v-html="t('meclee-ui.landings.main.media')"></p>
            </div>
            <div class="column is-12">
                <div class="columns logo-row big">
                    <div class="column">
                        <a href="https://delo.ua/news-companies/skolko-budet-stoit-psixologiceskaya-podderzka-vasix-sotrudnikov-v-2022-godu-issledovanie-390583/" target="_blank" rel="nofollow">
                        <img src="/meclee-ui/images/media/delo.svg" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://ain.ua/ru/2021/10/22/kejs-meclee/" target="_blank" rel="nofollow">
                         <img src="/meclee-ui/images/media/ain.svg" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a :href="t('meclee-ui.media.unian')" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/unian.svg" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://tsn.ua/lady/psychologia/ona/yak-vporatisya-iz-depresiyeyu-pid-chas-karantinu-1718743.html" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/tsn.svg" loading="lazy" />
                        </a>
                        </div>
                    <div class="column">
                        <a href="https://vesti.ua/poleznoe/semja/podarki-po-pochte-i-chokane-cherez-ekran-kakimi-budut-novogodnie-vecherinki " target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/vesti.svg" loading="lazy" />
                        </a>
                    </div>
                </div>
                <div class="columns logo-row">
                    <div class="column">
                        <a href="https://rubryka.com/article/psychology-children/" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/rubrica.svg" loading="lazy" />
                            </a>
                        </div>
                    <div class="column">
                        <a href="https://liza.ua/lifestyle/self-development/psiholog-onlajn-de-znajti-ta-navishho-potriben/" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/lisa.svg" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://www.rbc.ua/ukr/styler/ukraintsam-poluchit-besplatnuyu-meditsinskuyu-1650610277.html" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/rbc.svg" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://edinstvennaya.ua/psychology/self-development/35689-top-3-ukrainskih-servisa-po-poisku-psihologa" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/edinstvennaya.svg" loading="lazy" />
                        </a></div>
                    <div class="column">
                        <a href="https://viva.ua/lifestyle/15006-kak-prohodit-priem-u-psihologa-onlayn" target="_blank" rel="nofollow">
                           <img src="/meclee-ui/images/media/viva.svg" loading="lazy" />
                        </a>
                    </div>
                </div>
                <div class="columns logo-row">
                    <div class="column">
                        <a href="https://www.work.ua/articles/employer/2839/" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/work.svg" loading="lazy" />
                        </a></div>
                    <div class="column">
                        <a href="https://espreso.tv/yak-znayti-i-vibrati-psikhologa-onlayn-v-ukraini" target="_blank" rel="nofollow">
                            <span style="background: #0d794d; padding: 5px;">
                                <img src="/meclee-ui/images/media/espresso.svg" loading="lazy" />
                            </span>
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://meta.ua/uk/news/health/46999-yak-zrozumiti-scho-psiholog-dopomagae/" target="_blank" rel="nofollow">
                           <img src="/meclee-ui/images/media/meta.svg" loading="lazy" />
                        </a></div>
                    <div class="column">
                        <a href="https://www.capital.ua/ru/news/167388-kak-zarabotat-psikhologu-v-internete" target="_blank" rel="nofollow">
                            <img src="~/assets/meclee-ui/images/media/capital.png" loading="lazy" />
                        </a>
                    </div>
                    <div class="column">
                        <a href="https://ukraine.segodnya.ua/ukraine/pochemu-ukraincy-menshe-testiruyutsya-pyat-prichin-1502236.html" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/segodnya.svg" loading="lazy" />
                        </a>
                    </div>
                </div>
                <div class="columns logo-row">
                    <div class="column">
                        <a href="https://dev.ua/news/psy-1656833008" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/dev.svg" loading="lazy" />
                        </a>
                        </div>
                    <div class="column">
                        <a href="https://interfax.com.ua/news/press-release/839000.html" target="_blank" rel="nofollow">
                            <img src="/meclee-ui/images/media/interfax.svg" loading="lazy" />
                        </a></div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script setup>
const {t} = useI18n();
</script>
