import {Container} from "inversify";
import {marketplaceTypes} from "../../../../../di/types";
import {FilterCatalogUseCase} from "../../../application";

export function useCatalogRandom() {
  // @ts-ignore
  const { $container }: {$container: Container} = useNuxtApp();
  const filterCatalogUseCase = $container.get<FilterCatalogUseCase>(marketplaceTypes.FilterCatalog);

  async function fetchItems(services: string[]) {
    return await filterCatalogUseCase.execute({
      services: services,
      random: 1,
    });
  }

  return {
    fetchItems,
  }
}
