<template>
    <SplideSlide>
        <div class="review-item">
            <div class="review-header">
                <img class="photo" :src="photos[review.key]" :alt="review.name" loading="lazy" />
                <div class="user-info">
                    <p class="name">{{ t(review.name) }}</p>
                    <p class="specialty">{{ t(review.specialty) }}</p>
                </div>
            </div>
            <div class="review-text"> {{ t(review.text) }}
            </div>
        </div>
    </SplideSlide>
</template>
<script setup>
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

import MariaF from "../../../assets/images/reviews/00.webp"
import BohdanS from "../../../assets/images/reviews/01.webp"
import IrynaM from "../../../assets/images/reviews/02.webp"
import TarasL from "../../../assets/images/reviews/03.webp"
import MaksymR from "../../../assets/images/reviews/04.webp"
import AnnaU from "../../../assets/images/reviews/05.webp"
import KaterynaM from "../../../assets/images/reviews/06.webp"
import AnastasiiaN from "../../../assets/images/reviews/07.webp"

const { review } = defineProps({
    review: Object
})

const photos = {
    MariaF, BohdanS, IrynaM, TarasL, MaksymR, AnnaU, KaterynaM, AnastasiiaN
}
</script>
