<template>
    <div class="tab-container is-active" data-tab="for-me">
        <figure>
            <img src="/meclee-ui/images/illustration_forme.svg" loading="lazy" />
        </figure>
        <div class="content">
            <div class="content-container">
                <p class="title">{{ t('meclee-ui.landings.main.for-who.tabs.me.title') }}</p>
                <ul class="is-stylized">
                    <li v-for="(text, index) in content" :key="index">{{ t(text) }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const content = [
  'meclee-ui.landings.main.for-who.tabs.me.content.0',
  'meclee-ui.landings.main.for-who.tabs.me.content.1',
  'meclee-ui.landings.main.for-who.tabs.me.content.2',
  'meclee-ui.landings.main.for-who.tabs.me.content.3',
  'meclee-ui.landings.main.for-who.tabs.me.content.4',
  'meclee-ui.landings.main.for-who.tabs.me.content.5',
  'meclee-ui.landings.main.for-who.tabs.me.content.6',
];
</script>
