<template>
    <section class="section reviews-section">
        <div class="container">
            <p class="title">{{ t('meclee-ui.landings.main.reviews.title') }}</p>
            <Splide class="reviews splide" :options="sliderOptions">
                <ReviewItem v-for="(review, index) in reviews" :key="index" :review="review" />
            </Splide>
        </div>
    </section>
</template>
<script setup>
import "@splidejs/vue-splide/css";
import ReviewItem from "./Item.vue"

import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const { reviews } = defineProps({
    reviews: Array
})

const sliderOptions = {
    perPage: 4,
    perMove: 1,
    arrows: true,
    arrowPath: 'M11.4462659,22 C11.4972678,22 11.5503947,21.9902248 11.6056466,21.9706745 C11.6608986,21.9511241 11.7097753,21.9217986 11.7522769,21.8826979 L11.7522769,21.8826979 L17.8724954,16.2404692 C17.9574985,16.1622678 18,16.0684262 18,15.9589443 C18,15.8494624 17.9574985,15.7556207 17.8724954,15.6774194 L17.8724954,15.6774194 L11.8415301,10.1173021 C11.756527,10.0391007 11.6523983,10 11.5291439,10 C11.4058895,10 11.3017608,10.0391007 11.2167577,10.1173021 C11.1317547,10.1955034 11.0892532,10.2913001 11.0892532,10.4046921 C11.0892532,10.5180841 11.1317547,10.6138807 11.2167577,10.6920821 L11.2167577,10.6920821 L16.9544627,15.9589443 L11.1275046,21.3196481 C11.0425015,21.3978495 11,21.4916911 11,21.601173 C11,21.7106549 11.0425015,21.8044966 11.1275046,21.8826979 C11.1700061,21.9217986 11.2188828,21.9511241 11.2741348,21.9706745 C11.3293868,21.9902248 11.3867638,22 11.4462659,22 Z',
    pagination: true,
    gap: '30px',
    breakpoints: {
        768: {
            perPage: 1,
            pagination: false,
            arrows: false,
            gap: '20px',
            padding: {
                left: '0',
                right: '35px',
            },
        },
    }
};
</script>
