<template>
    <section class="section for-who-section">
        <div class="container">
            <div class="title">
                <h2>{{ t('meclee-ui.landings.main.for-who.title') }}</h2>
            </div>
            <div class="tabs">
                <template v-for="tab in tabs" :key="tab.key">
                    <TabButton :class="[activeComponent.key === tab.key ? 'is-active' : '']" @click="setActiveTab(tab)">
                      {{ t(tab.title) }}
                    </TabButton>
                </template>
            </div>
            <div class="tabs-content">
                <component :is="activeComponent.comp" />
            </div>
        </div>
    </section>
</template>
<script setup>
import TabButton from "../components/Main/Tabs/TabButton.vue"
import ForMe from "../components/Main/Tabs/ForMe.vue"
import ForKids from "../components/Main/Tabs/ForKids.vue"
import ForPair from "../components/Main/Tabs/ForPair.vue"
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const { tabs } = defineProps({
    tabs: Array
})

const tabsComponents = [
    {
        key: "ForMe",
        comp: ForMe
    },
    {
        key: "ForKids",
        comp: ForKids
    },
    {
        key: "ForPair",
        comp: ForPair
    }
]
const activeComponent = ref(tabsComponents[0])

const setActiveTab = (tab) => {
    let clicked = tabsComponents.find(item => item.key === tab.key)
    if (clicked) activeComponent.value = clicked
}

</script>
