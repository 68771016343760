<template>
  <div class="columns block">
    <div class="column is-7-tablet image">
      <slot name="image" />
    </div>
    <div class="column is-5-tablet">
      <div class="content">
        <p class="header"><slot name="header" /></p>

        <slot />
      </div>
    </div>
  </div>
</template>
<script setup>
</script>