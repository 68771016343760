<template>
  <section class="section how-to-start-section for-customer">
    <div class="container">
      <p class="title">{{ t('meclee-ui.landings.main.how-to-start.title') }}</p>

      <HowToStartBlock>
        <template #image>
          <img src="~/assets/meclee-ui/images/image_main_1.jpg" loading="lazy" />
        </template>
        <template #header>{{ t('meclee-ui.landings.main.how-to-start.list.0.title') }}</template>
        <p class="subheader"> {{ t('meclee-ui.landings.main.how-to-start.list.0.subtitle') }}
        </p>
        <Button :tag="NuxtLink" :to="buttonLink">{{
          t('meclee-ui.landings.main.how-to-start.list.0.button')
        }}</Button>
      </HowToStartBlock>

      <HowToStartBlock class="is-inverted">
        <template #header>{{ t('meclee-ui.landings.main.how-to-start.list.1.title') }}</template>
        <p class="subheader">{{ t('meclee-ui.landings.main.how-to-start.list.1.subtitle') }}
        </p>
        <template #image>
          <img src="~/assets/meclee-ui/images/image_main_2.jpg" loading="lazy" />
        </template>
      </HowToStartBlock>

      <HowToStartBlock>
        <template #image>
          <img src="~/assets/meclee-ui/images/image_main_3.jpg" loading="lazy" />
        </template>
        <template #header>{{
          t('meclee-ui.landings.main.how-to-start.list.2.title')
        }}</template>
        <p class="subheader">{{ t('meclee-ui.landings.main.how-to-start.list.2.subtitle') }}
        </p>
      </HowToStartBlock>

      <HowToStartBlock class="is-inverted">
        <template #image>
          <img src="~/assets/meclee-ui/images/image_main_4.jpg" loading="lazy" />
        </template>
        <template #header>{{ t('meclee-ui.landings.main.how-to-start.list.3.title') }}</template>
        <div class="subheader">
          <p>{{ t('meclee-ui.landings.main.how-to-start.list.3.subtitle.0') }}</p>
          <div class="logo" style="display: flex">
            <img src="/meclee-ui/images/visa_logo.svg" class="visa" loading="lazy" />
            <img src="/meclee-ui/images/mc_logo.svg" class="mc" loading="lazy" />
          </div>
          <p>{{ t('meclee-ui.landings.main.how-to-start.list.3.subtitle.1') }}</p>
          <div class="logo" style="display: flex">
            <img src="/meclee-ui/images/apay_logo.svg" class="apple-pay" loading="lazy" />
            <img src="/meclee-ui/images/gpay_logo.svg" class="google-pay" loading="lazy" />
          </div>
        </div>
      </HowToStartBlock>

      <div class="cta">
        <Button :tag="NuxtLink" :to="buttonLink">{{ t('meclee-ui.landings.main.how-to-start.button') }}</Button>
      </div>
    </div>
  </section>
</template>
<script setup>
import { NuxtLink } from "#components";
import Button from "../components/Button.vue";
import HowToStartBlock from "../components/Main/HowToStart/Block";

import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const { buttonLink } = defineProps({
  buttonLink: {
    type: String,
    required: true,
  },
});
</script>
