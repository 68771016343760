<template>
  <div>
    <div class="flex flex-col lg:flex-row items-center justify-center">
      <ul class="flex flex-row items-center lg:flex-1 justify-center space-x-1 sm:space-x-3">
        <li
          class="rounded-full border-2 border-meclee-blue py-2 px-2 sm:px-5 text-sm lg:text-base sm:font-bold cursor-pointer hover:bg-meclee-blue hover:border-white hover:text-white"
          :class="{'bg-meclee-blue border-white text-white': selectedTab === tab}"
          :key="`ast-${tab}-tab`"
          v-for="tab in services"
          @click="selectedTab = tab"
        >
          {{ labels[tab] }}
        </li>
      </ul>
      <NuxtLink :key="`ast-${tab}-content`"
                v-for="tab in services"
                v-show="selectedTab === tab"
                :to="localePath(`/psychologists/services:${tab}`)"
                class="mt-6 lg:mt-0 lg:ml-auto text-gray-600 hover:text-meclee-blue"
      >
        {{ t('common.viewAll') }}
      </NuxtLink>
    </div>

    <div class="mt-6">
      <div :key="`ast-${tab}-content`" v-for="tab in services">
        <div v-if="selectedTab === tab">
        <Splide :options="sliderOptions" class="splide psychologist-slider">
          <slot name="content" :tab="tab" />
        </Splide>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const {t} = useI18n();
const localePath = useLocalePath();
const services = ['individual', 'family', 'kids'];
const selectedTab = ref('individual');

const labels = {
  individual: t('meclee-ui.landings.main.for-who.tabs.me.title'),
  kids: t('meclee-ui.landings.main.for-who.tabs.kids.title'),
  family: t('meclee-ui.landings.main.for-who.tabs.pair.title'),
}

const sliderOptions = {
  type: 'slide',
  perPage: 3,
  perMove: 1,
  arrows: false,
  pagination: false,
  gap: '30px',
  breakpoints: {
    1000: {
      perPage: 3,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
    768: {
      perPage: 2,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
    450: {
      perPage: 1,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
  }
}
</script>

<style>
body.meclee-ui .psychologist-slider .splide__track {
  width: calc(100% + 24px);
}
</style>
